// @ts-nocheck
import config from './config/config';

import { initializeApp, FirebaseOptions } from 'firebase/app';

import { getAuth, Auth } from 'firebase/auth';
import { getDatabase, Database } from 'firebase/database';
import {
  RemoteConfig,
  getRemoteConfig,
  isSupported,
} from 'firebase/remote-config';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  AppCheck,
} from 'firebase/app-check';

const firebaseConfig: FirebaseOptions = {
  apiKey: config.firebase.key,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
  appId: config.firebase.appId,
};

let app: FirebaseApp;
let auth: Auth;
let appCheck: AppCheck;
let database: Database;
let remoteConfig: RemoteConfig;

if (typeof window !== 'undefined') {
  app = app || initializeApp(firebaseConfig);

  appCheck =
    appCheck ||
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(
        config.firebase.reCaptchaSiteKey,
      ),
      isTokenAutoRefreshEnabled: true,
    });

  auth = auth || getAuth(app);

  database = database || getDatabase(app);

  if (isSupported(app)) {
    try {
      remoteConfig = remoteConfig || getRemoteConfig(app);
    } catch (e) {
      console.error('Error occurred while loading remote config', e);
      remoteConfig = {
        app,
        settings: {},
      };
    }
  }

  remoteConfig.settings.minimumFetchIntervalMillis = 30 * 60 * 1000;
}

export { auth, database, remoteConfig };

export default app;
