import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  ensureInitialized,
  fetchAndActivate,
  getAll,
} from 'firebase/remote-config';

import { firebaseContext } from './FirebaseProvider';
import { Scalars } from '../__generated__/pge-types';

export interface ContactDataIntercept {
  isEnabled?: boolean;
  startDate?: Scalars['LocalDate'];
  endDate?: Scalars['LocalDate'];
}

interface FeatureFlags {
  contactDataIntercept?: ContactDataIntercept;
  displayMyAccountInfoBanner: boolean;
  isClosedAccountsEnabled?: boolean;
  isEVProgramEnabled: boolean;
  isEnergyTrackerMigration: boolean;
  isOutageByAddressEnabled: boolean;
  isPGEPlusEVEnabled: boolean;
  isPGEPlusInstallerSignInEnabled: boolean;
  isNewAccountDashboardEnabled: boolean;
  isClientProgramPromoModalEnabled: boolean;
  isSecureSiteModalEnabled: boolean;
  isPGEPlusRebateOnlyEnabled: boolean;
  isPspsActivated: boolean;
  isPlannedDownTime: boolean;
  isPowerPortfolioEnabled?: boolean;
  isTPAEnabled: boolean;
  loading: boolean;
  moveEnabled: boolean;
  startStopEnabled: boolean;
}

const defaultFlags: FeatureFlags = {
  contactDataIntercept: undefined,
  displayMyAccountInfoBanner: false,
  isClosedAccountsEnabled: false,
  isEVProgramEnabled: false,
  isEnergyTrackerMigration: false,
  isOutageByAddressEnabled: false,
  isPGEPlusEVEnabled: false,
  isPGEPlusInstallerSignInEnabled: false,
  isNewAccountDashboardEnabled: false,
  isClientProgramPromoModalEnabled: false,
  isSecureSiteModalEnabled: false,
  isPGEPlusRebateOnlyEnabled: false,
  isPspsActivated: false,
  isPlannedDownTime: false,
  isPowerPortfolioEnabled: false,
  isTPAEnabled: false,
  loading: true,
  moveEnabled: false,
  startStopEnabled: false,
};

export const featureFlagsContext = React.createContext<FeatureFlags>(
  defaultFlags,
);

const { Provider } = featureFlagsContext;

export const FeatureFlagsProvider = (props: any) => {
  const remoteConfig = useContext(firebaseContext).remoteConfig!;
  const [value, setConfigValue] = useState(defaultFlags);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const url = new URL(window.location.href);
        let overrides: Partial<FeatureFlags> = {};
        if (url.searchParams.get('_f')) {
          try {
            overrides = JSON.parse(
              atob(decodeURIComponent(url.searchParams.get('_f')!)),
            );
            remoteConfig.settings.minimumFetchIntervalMillis =
              12 * 60 * 60 * 1000;
          } catch (e) {
            console.error(
              `Error occurred while parsing _f parameter: ${e}`,
              (e as any).stack,
            );
          }
        }

        await ensureInitialized(remoteConfig);
        await fetchAndActivate(remoteConfig);
        const allConfig = await getAll(remoteConfig);

        const config = Object.keys(defaultFlags).reduce((accum, key) => {
          if (key === 'contactDataIntercept' && allConfig[key]) {
            (accum as any)[key] = JSON.parse(allConfig[key].asString());
            return accum;
          }

          (accum as any)[key] = _.isEmpty(allConfig[key])
            ? (defaultFlags as any)[key]
            : allConfig[key].asBoolean();
          return accum;
        }, {});

        setConfigValue(
          () => ({ ...config, ...overrides, loading: false } as FeatureFlags),
        );
      } catch (error) {
        console.error('Error fetching remote config:', error);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchConfig();
  }, []);

  return <Provider value={value}>{props.children}</Provider>;
};
