import React from 'react';
import { Database } from 'firebase/database';
import { database, remoteConfig } from '../firebase';
import { RemoteConfig } from 'firebase/remote-config';

const firebaseContext = React.createContext<{
  database: Database | null;
  remoteConfig: RemoteConfig | null;
}>({ database: null, remoteConfig: null });

const { Provider } = firebaseContext;

const FirebaseProvider = (props: any) => {
  return (
    <Provider value={{ database, remoteConfig }}>{props.children}</Provider>
  );
};

export { FirebaseProvider, firebaseContext };
