// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-person-verify-access-token-tsx": () => import("./../src/pages/add-person-verify-access-token.tsx" /* webpackChunkName: "component---src-pages-add-person-verify-access-token-tsx" */),
  "component---src-pages-auth-forgot-password-action-needed-tsx": () => import("./../src/pages/auth/forgot-password-action-needed.tsx" /* webpackChunkName: "component---src-pages-auth-forgot-password-action-needed-tsx" */),
  "component---src-pages-auth-forgot-password-tsx": () => import("./../src/pages/auth/forgot-password.tsx" /* webpackChunkName: "component---src-pages-auth-forgot-password-tsx" */),
  "component---src-pages-auth-quick-login-tsx": () => import("./../src/pages/auth/quick-login.tsx" /* webpackChunkName: "component---src-pages-auth-quick-login-tsx" */),
  "component---src-pages-auth-sign-in-tsx": () => import("./../src/pages/auth/sign-in.tsx" /* webpackChunkName: "component---src-pages-auth-sign-in-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-change-email-confirmation-tsx": () => import("./../src/pages/change-email/confirmation.tsx" /* webpackChunkName: "component---src-pages-change-email-confirmation-tsx" */),
  "component---src-pages-client-program-promo-tsx": () => import("./../src/pages/client-program-promo.tsx" /* webpackChunkName: "component---src-pages-client-program-promo-tsx" */),
  "component---src-pages-error-index-tsx": () => import("./../src/pages/error/index.tsx" /* webpackChunkName: "component---src-pages-error-index-tsx" */),
  "component---src-pages-guest-pay-index-tsx": () => import("./../src/pages/guest-pay/index.tsx" /* webpackChunkName: "component---src-pages-guest-pay-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-installer-auth-tsx": () => import("./../src/pages/installer/auth.tsx" /* webpackChunkName: "component---src-pages-installer-auth-tsx" */),
  "component---src-pages-installer-finalize-registration-tsx": () => import("./../src/pages/installer/finalize-registration.tsx" /* webpackChunkName: "component---src-pages-installer-finalize-registration-tsx" */),
  "component---src-pages-installer-forgot-password-tsx": () => import("./../src/pages/installer/forgot-password.tsx" /* webpackChunkName: "component---src-pages-installer-forgot-password-tsx" */),
  "component---src-pages-installer-secure-index-tsx": () => import("./../src/pages/installer/secure/index.tsx" /* webpackChunkName: "component---src-pages-installer-secure-index-tsx" */),
  "component---src-pages-news-archive-tsx": () => import("./../src/pages/news-archive.tsx" /* webpackChunkName: "component---src-pages-news-archive-tsx" */),
  "component---src-pages-ocpa-redirect-tsx": () => import("./../src/pages/ocpa-redirect.tsx" /* webpackChunkName: "component---src-pages-ocpa-redirect-tsx" */),
  "component---src-pages-outages-index-tsx": () => import("./../src/pages/outages/index.tsx" /* webpackChunkName: "component---src-pages-outages-index-tsx" */),
  "component---src-pages-outages-mobile-app-index-tsx": () => import("./../src/pages/outages-mobile-app/index.tsx" /* webpackChunkName: "component---src-pages-outages-mobile-app-index-tsx" */),
  "component---src-pages-pge-service-area-map-index-tsx": () => import("./../src/pages/pge-service-area-map/index.tsx" /* webpackChunkName: "component---src-pages-pge-service-area-map-index-tsx" */),
  "component---src-pages-quick-pay-tsx": () => import("./../src/pages/quick-pay.tsx" /* webpackChunkName: "component---src-pages-quick-pay-tsx" */),
  "component---src-pages-quick-view-bill-tsx": () => import("./../src/pages/quick-view-bill.tsx" /* webpackChunkName: "component---src-pages-quick-view-bill-tsx" */),
  "component---src-pages-register-account-picker-tsx": () => import("./../src/pages/register/account-picker.tsx" /* webpackChunkName: "component---src-pages-register-account-picker-tsx" */),
  "component---src-pages-register-action-needed-tsx": () => import("./../src/pages/register/action-needed.tsx" /* webpackChunkName: "component---src-pages-register-action-needed-tsx" */),
  "component---src-pages-register-business-tsx": () => import("./../src/pages/register/business.tsx" /* webpackChunkName: "component---src-pages-register-business-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-register-residential-tsx": () => import("./../src/pages/register/residential.tsx" /* webpackChunkName: "component---src-pages-register-residential-tsx" */),
  "component---src-pages-register-success-tsx": () => import("./../src/pages/register/success.tsx" /* webpackChunkName: "component---src-pages-register-success-tsx" */),
  "component---src-pages-search-tsx": () => import("./../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-secure-tsx": () => import("./../src/pages/secure.tsx" /* webpackChunkName: "component---src-pages-secure-tsx" */),
  "component---src-pages-ssm-index-tsx": () => import("./../src/pages/ssm/index.tsx" /* webpackChunkName: "component---src-pages-ssm-index-tsx" */),
  "component---src-pages-ssm-start-index-tsx": () => import("./../src/pages/ssm/start/index.tsx" /* webpackChunkName: "component---src-pages-ssm-start-index-tsx" */),
  "component---src-pages-start-stop-move-residential-index-tsx": () => import("./../src/pages/start-stop-move/residential/index.tsx" /* webpackChunkName: "component---src-pages-start-stop-move-residential-index-tsx" */),
  "component---src-pages-storminfo-tsx": () => import("./../src/pages/storminfo.tsx" /* webpackChunkName: "component---src-pages-storminfo-tsx" */),
  "component---src-pages-supply-and-renewables-index-tsx": () => import("./../src/pages/supply-and-renewables/index.tsx" /* webpackChunkName: "component---src-pages-supply-and-renewables-index-tsx" */),
  "component---src-pages-user-management-tsx": () => import("./../src/pages/user-management.tsx" /* webpackChunkName: "component---src-pages-user-management-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../src/templates/article-template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-basic-template-tsx": () => import("./../src/templates/basic-template.tsx" /* webpackChunkName: "component---src-templates-basic-template-tsx" */),
  "component---src-templates-blog-article-template-tsx": () => import("./../src/templates/blog-article-template.tsx" /* webpackChunkName: "component---src-templates-blog-article-template-tsx" */),
  "component---src-templates-blog-author-template-tsx": () => import("./../src/templates/blog-author-template.tsx" /* webpackChunkName: "component---src-templates-blog-author-template-tsx" */),
  "component---src-templates-blog-category-template-tsx": () => import("./../src/templates/blog-category-template.tsx" /* webpackChunkName: "component---src-templates-blog-category-template-tsx" */),
  "component---src-templates-blog-tag-template-tsx": () => import("./../src/templates/blog-tag-template.tsx" /* webpackChunkName: "component---src-templates-blog-tag-template-tsx" */),
  "component---src-templates-list-template-tsx": () => import("./../src/templates/list-template.tsx" /* webpackChunkName: "component---src-templates-list-template-tsx" */),
  "component---src-templates-news-landing-template-tsx": () => import("./../src/templates/news-landing-template.tsx" /* webpackChunkName: "component---src-templates-news-landing-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../src/templates/news-template.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-promotion-template-tsx": () => import("./../src/templates/promotion-template.tsx" /* webpackChunkName: "component---src-templates-promotion-template-tsx" */)
}

